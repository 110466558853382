import React from 'react'
import what1 from "../Image/what1.png";
import what2 from "../Image/rectangle (3).jpg";
import what3 from "../Image/rectangle 2.jpg";
import {  Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import '../Stylesheet/component.css';

const What = () => {
    const know = [
        {
          id: 1,
          Image: what1,
          title: "Transparency",
          text: "Honesty and transparency are the cornerstones of our business.",
        },
        {
          id: 2,
          Image: what2,
          title: "Innovation",
          text: "We embrace innovation as the driving force behind our growth.",
        },
        {
          id: 3,
          Image: what3,
          title: "Sustainability",
          text: "We are committed to environmentally responsible practices.",
        },
      ];
  return (
    <div className="known">
          <h2 data-aos="zoom-in-up"  data-aos-duration="2000" className='h2'>OUR QUALITIES</h2>
          <div className="known_body">
            {know.map((item) => (
              <div data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000" className="known_card">
                <div className="imgg">
                    <img src={item.Image} alt="" />
                </div>
                
                <div className="know_text">
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  <HashLink smooth to="/about#core">
                    Read more{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9 18L15 12L9 6"
                        stroke="#111E48"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </HashLink>
                </div>
              </div>
            ))}
          </div>
          <Link to="/gallery">View Our Project</Link>         
        </div>
  )
}

export default What