import React from 'react'
import Logo from "../Image/white.png"
import '../Stylesheet/component.css';
import { Link } from 'react-router-dom';

export const Footer = () => {

    // const scrollToTop = () => {
    //     window.scrollTo({
    //       top: 0,
    //       behavior: "smooth", 
    //     });
    //   };

     

  return (
    <footer >
        {/* <div className="red"><img onClick={scrollToTop}  src={scroll} alt="" /></div> */}
        <div className="footer">
            <div className="foot_head">
            <img src={Logo} alt="" />
            <div className="email">
                <p>Subscribe to our newsletter</p>
                <div className="send">
                    <input type="email" name="suscribe" id="" placeholder='Your Email'/>
                    <button>Send</button>
                </div>
            </div>

             </div>
             
            <div className="foot_body">
                <div className="a">
                    <h3>Company</h3>
                    <Link to="/">Home</Link>
                    <Link to="/about">About Us</Link>
                    <Link to="/services" >Services</Link>
                </div>
                <div className="a">
                    <span><br/></span>
                    <Link to="/gallery">Gallery</Link>
                    
                    <Link to="/contact" >Contact Us</Link>
                    <span></span>
                </div>
                <div className="a">
                <h3>Follow Us</h3>
                    <Link >Facebook</Link>
                    <Link >Instagram</Link>
                    <Link >Twitter</Link>
                </div>
                <div className="a">
                <h3>Community</h3>
                    <Link >Help</Link>
                    <Link >Terms & Conditions</Link>
                    <Link  >Privacy Policy</Link>
                </div>
                <div className="a">
                <h3>Reach Out Us</h3>
                   <p>Address: No 2. Olabisi Close, Odogunyan Ikorodu, Lagos State.</p>
                    <p>Phone: +234 701- 042-2306</p>
                    <p>Mail: vertexflexltd@gmail.com</p>
                </div>
            </div>
            <div className="c_tag">
                <p>Copyright © 2023 Vertex Flex Limited. All Rights Reserved.</p>
            </div>
        </div>
        
    </footer>
  )
}
