import React, {useState} from 'react'
import AnimatedPage from '../components/AnimatedPage'
import { Header } from '../components/Header'
import gall from "../Image/Frame 467136.png"
import rect1 from "../Image/rectangle (1).jpg";
import rect2 from "../Image/rectangle (2).jpg";
import rect3 from "../Image/rectangle (3).jpg";
import rect4 from "../Image/rectangle (4).jpg";
import rect5 from "../Image/rectangle (5).jpg";
import rect6 from "../Image/rectangle (6).jpg";
import rect7 from "../Image/rectangle (7).jpg";
import rect8 from "../Image/rectangle (8).jpg";
import rect9 from "../Image/rectangle (9).jpg";
import rect10 from "../Image/rectangle (10).jpg";
import rect11 from "../Image/rectangle (11).jpg";
import rect12 from "../Image/rectangle (12).jpg";
import rect13 from "../Image/rectangle (13).jpg";
import rect14 from "../Image/rectangle (14).jpg";
import rect15 from "../Image/rectangle (15).jpg";

import "../Stylesheet/styles.css";


const Gallery = () => {
  const rectArray = [
    rect15,
    rect1,
    rect2,
    rect3,
    rect4,
    rect5,
    rect6,
    rect7,
    rect8,
    rect9,
    rect10,
    rect11,
    rect12,
    rect13,
    rect14,
  
  ];

  
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalVisible(false);
  };


  return (
    <AnimatedPage>
      <div className="main_gallery">
      <Header image ={gall} title="Gallery" body="Read through a list of our services and what we do."/>

      <div className="rect_img">
      {rectArray.map((rect, index) => (
        <img onClick={() => openModal(rect)} key={index} src={rect} alt={`Rectangle ${index + 50}`} />
        
      ))}
      </div>


      {modalVisible && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal">
              <span className="close-button" onClick={closeModal}>&times;</span>
              <img src={selectedImage} alt="Selected-e" />
            </div>
          </div>
        )}
      </div>
    </AnimatedPage>
    
  )
}

export default Gallery