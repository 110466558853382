import React, { useState, useEffect, useRef } from "react";
import "../Stylesheet/home.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../Image/logo.png";
import icon1 from "../Image/Rectangle 20794.png"
// import icon2 from "../Image/Rectangle 20795.png"
// import icon3 from "../Image/Rectangle 20796.png"
// import icon4 from "../Image/Rectangle 20797.png"
import { RiMenuFill, RiCloseFill } from "react-icons/ri";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const hide = () => setIsOpen(false);
  const show = () => setIsOpen(true);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [fix, setFix] = useState(false);

  function setFixed() {
    if (window.scrollY >= 40) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <div className={fix ? "navbar fixed" : "navbar"}>
      <div className="nav_head">
      <button className="nav_toggle" onClick={() => setIsOpen(!isOpen)}>
      {isOpen ? <RiCloseFill /> : <RiMenuFill />}
      </button>
        
      <div className="log">
          <img className="logo" src={logo} alt="" />      
      </div>
      <div className="nav_social">
      
        <div className="social_icon">
        <Link target="_blank" to="https://www.facebook.com/profile.php?id=100063491323555&mibextid=ZbWKwL"><img src={icon1} alt="" /></Link>
        {/* <Link><img src={icon2} alt="" /></Link>
        <Link><img src={icon3} alt="" /></Link>
        <Link><img src={icon4} alt="" /></Link> */}
        </div>
      <span className="pp">Phone: +234 701- 042-2306</span></div>
      



      </div>

      <div className="nav_body">
               
      <div ref={dropdownRef}  className={`nav_link ${isOpen ? 'show_nav' : 'hide_nav'} `}>
        
        <NavLink  onClick={toggle} onBlur={hide} onFocus={show} to="/">
          {" "}
          HOME
        </NavLink>
        <NavLink onClick={toggle} onBlur={hide} onFocus={show} to="/about">
        ABOUT US
        </NavLink>
        <NavLink   onClick={toggle} onBlur={hide} onFocus={show} to="/services">
        OUR SERVICES
        </NavLink>
        <NavLink onClick={toggle} onBlur={hide} onFocus={show} to="/gallery">
        GALLERY
        </NavLink>

        <NavLink
          onClick={toggle}
          onBlur={hide}
          onFocus={show}
          className="contact_tag"
          to="/contact"
        >
          CONTACT US
        </NavLink>
        <NavLink to="/quote" className="qe" onClick={toggle} onBlur={hide} onFocus={show} >
        REQUEST A QUOTE
        </NavLink>

       

      </div>
      <div className="que">
        <NavLink to="/quote" className="bb" onClick={toggle} onBlur={hide} onFocus={show} >
        REQUEST A QUOTE
        </NavLink>
      </div>
      
      </div>
     
      
    </div>
  );
};

export default Navbar;
