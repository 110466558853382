import React from "react";
import Navbar from "../components/Navbar";
import "../Stylesheet/home.css";
import AnimatedPage from "../components/AnimatedPage";
import { Link } from "react-router-dom";
import What from "./What";
import icon1 from "../Image/Rectangle 20801.png";
import icon2 from "../Image/Rectangle 20802.png";
import icon3 from "../Image/Rectangle 20803.png";
import icon4 from "../Image/Rectangle 20804.png";
import icon5 from "../Image/Rectangle 20805.png";

const Home = () => {
  const icon = [
    {
      image: icon1,
      body: "Roof Installation",
    },
    {
      image: icon2,
      body: "Fabrication & Erecting",
    },
    {
      image: icon3,
      body: "Maintenance Service",
    },
    {
      image: icon4,
      body: "Project Management",
    },
    {
      image: icon5,
      body: "Carpentry",
    },
  ];

  return (
    <AnimatedPage>
      <div className="home_container">
        <Navbar />

        <div className="home">
          <div className="main_home">
            <div
            className="home_text"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="2000"
          >
            <h2>
              Premier Partner in Construction and Roof Installation Services
            </h2>
            <p>
              A team of highly skilled professionals who are dedicated to
              providing you with roof solutions, exceptional quality
              workmanship, and superior customer service.
            </p>
            <Link to="/services">Our Servies</Link>
          </div>
          </div>
          
        </div>
        <div className="about_us">
          <div className="us_text">
            <div className="text_us">
              <h2 data-aos="fade-up" data-aos-duration="1000" className="h2">
              ABOUT US
            </h2>

            <p data-aos="fade-left" data-aos-duration="1500">
              We are proud to be a trusted name in the industry, with our
              headquarters located at No 2. Olabisi close, Odogunyan Ikorodu,
              Lagos state. Our core mission is to provide a comprehensive suite
              of services that cater to all your construction needs, including
              meticulous maintenance services and expert project management.
            </p>
            <Link to="/about" data-aos="fade-right" data-aos-duration="2000">
              WHO WE ARE
            </Link>
            </div>
            
          </div>
        </div>
        <div className="our">
          <h2 data-aos="zoom-in-up" data-aos-duration="2000" className="h2">
            OUR SERVICES
          </h2>
          <div className="our_icon">
            {icon.map((item) => (
              <div className="our_card">
                <img
                  data-aos="zoom-in-down"
                  data-aos-duration="2000"
                  src={item.image}
                  alt=""
                />
                <p
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  data-aos-duration="2000"
                >
                  {item.body}
                </p>
              </div>
            ))}
          </div>
          <Link to="/services" data-aos="fade-in" data-aos-duration="2000">
            READ MORE
          </Link>
        </div>

        <What />
        <div data-aos="zoom-in-up" data-aos-duration="2000" className="ready">
          <h2 data-aos="fade-up" data-aos-duration="1000" className="h2">
            Ready to get started?{" "}
          </h2>
          <p>
            Fill the form and send us a message to get started today on your
            roof installation.
          </p>
          <div className="ready_btn">
            <Link
              data-aos="fade-left"
              data-aos-duration="500"
              className="conn"
              to="/contact"
            >
              CONTACT US
            </Link>
            <Link
              data-aos="fade-right"
              data-aos-duration="500"
              className="uss"
              to="/quote"
            >
              GET A QUOTE
            </Link>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};

export default Home;
