import './App.css';
import { BrowserRouter, Route , Routes} from 'react-router-dom';
import Home from './Page/Home';
import About from './Page/About';
import Services from './Page/Services';
import Gallery from './Page/Gallery';
import Contact from './Page/Contact';
import ScrollToTop from './components/ScrollToTop';
import { Footer } from './components/Footer';
import Quote from './Page/Quote';

 


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>          
          <Route index element={<Home/>}/>
          <Route path="/about" element={<About/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contact" element={<Contact/>} />   
          <Route path="/quote"  element={<Quote/>} />   
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;