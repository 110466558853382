import React from "react";
import "../Stylesheet/about.css";
import AnimatedPage from "../components/AnimatedPage";
import { Header } from "../components/Header";
import aboutt from "../Image/Frame 467137.png";
import big from "../Image/rectangle (8).jpg";
import tick from "../Image/tick.png"
import sat from "../Image/Rectangle 20757.svg"
import sat1 from "../Image/Rectangle 20758.svg"
import back from "../Image/Rectangle 20767.png"
import { Link } from "react-router-dom";

const About = () => {

  const coreValues = [
    {
      title: "Integrity and Transparency",
      description:
        "Honesty and transparency are the cornerstones of our business. We conduct ourselves with the highest ethical standards, fostering trust among our clients, employees, and partners.",
    },
    {
      title: "Safety",
      description:
        "We prioritize the safety and well-being of our team members, clients, and communities. Our strict safety protocols ensure that every project is executed without compromising health or security.",
    },
    {
      title: "Sustainability",
      description:
        "We are committed to environmentally responsible practices. We strive to minimize our environmental footprint and promote sustainable construction methods and materials.",
    },
    {
      title: "Continuous Learning",
      description:
        "We believe in the power of knowledge and continuous improvement. Our team stays up-to-date with industry trends and best practices to deliver cutting-edge solutions.",
    },
    {
      title: "Quality Excellence",
      description:
        "We are unwavering in our commitment to delivering exceptional quality in every aspect of our work, ensuring lasting results that stand the test of time.",
    },
    {
      title: "Innovation",
      description:
        "We embrace innovation as the driving force behind our growth. We continually explore new technologies, methods, and materials to enhance our services and stay at the forefront of the industry.",
    },
  ];
  



  const stat = [
    {
      icon: sat,
      title: "Vision Statement",
      body: "To be the foremost innovators in construction and roofing solutions, setting industry standards for quality, sustainability, and customer satisfaction. We aspire to transform dreams into reality, one project at a time, and be recognized as the trusted partner for all construction needs, both locally and internationally."
    },
    {
      icon: sat1,
      title: "Mission Statement",
      body: "At Vertex Flex Ltd, our mission is to provide comprehensive construction and roofing solutions that exceed our clients' expectations. We are committed to delivering high-quality services, utilizing cutting-edge technology and sustainable practices. Our dedicated team of professionals strives for excellence in every project, ensuring safety, efficiency, and customer satisfaction. We aim to contribute positively to the communities we serve and to be a responsible steward of the environment. Vertex Flex Ltd is dedicated to building a better future, one project at a time."
    },
  ]
  const quality = [
    {
      head: "Professionals",
      body: "At Vertex Flex Ltd, we have a team of highly skilled professionals who are dedicated to providing you with roof solutions, exceptional quality workmanship, and superior customer service.",
    },
    {
      head: "Compatibility",
      body: "Additionally, we are your reliable source for high-quality roofing sheets and construction materials. Whether you require carpentry services, skilled labor, or a dedicated general contractor, Vertex Flex Ltd is your one-stop solution.",
    },
    {
      head: "Commitment",
      body: "We take pride in our commitment to delivering top-notch craftsmanship and ensuring that your projects are executed seamlessly, on time, and within budget.",
    },
    {
      head: "Expertise",
      body: "Our core mission is to provide a comprehensive suite of services that cater to all your construction needs, including meticulous maintenance services and expert project management.",
    },
  ];
  return (
    <AnimatedPage>
      <div className="about">
        <Header
          image={aboutt}
          title="About us"
          body="A team of highly skilled professionals who are dedicated to providing you with roof solutions, exceptional quality workmanship, and superior customer service."
        />

        <div className="quality">
          <div className="quality_text">
            <h2 data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
              Experienced & quality <br />
              roofing services providers
            </h2>
            <p data-aos="fade-up"
     data-aos-easing="linear"
     data-aos-duration="1500">
              Vertex Flex Ltd is your premier partner in construction and roof
              installation services with a registered RC number: 1837920. We
              specialize heavily in industrial, commercial, and residential roof
              installation.
            </p>
            <div className="quality_list">
              {quality.map((item) => (
                <div className="quality_card">
                  <h2 >
                    <  img src={tick}alt="" />
                    {item.head}
                  </h2>
                  <p>{item.body}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="quality_img">
            <img data-aos="fade-right"
              data-aos-anchor-placement="top-center" data-aos-duration="2000" className="big" src={big} alt="" />
          
          </div>
        </div>
        <div className="stat">
          <div className="stat_text">
            <h2>Corporate Statements</h2> 
            <div className="stat_list">
              {stat.map((item)=> 
                <div  className="stat_card">
                  <img src={item.icon} alt="" />
                  <div className="state">
                    <h3>{item.title}</h3>
                    <p>{item.body}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
       
            <img className="ss" src={back} alt="" />
  
          
        </div>

        <div className="core-values-section">
          <div id="core"  className="core-values-primary-holder">
              <div className="core-values-primary">
                <div className="intro">
                  <h1  >Core Values</h1>
                  <p  >Our core values and professional qualities as a company</p>
                  <Link to='/services'>OUR SERVICES</Link>
                </div>

                <div  className="core-values-card primary">
                  <h4><img src={tick}alt="" />Customer-Centric Approach</h4>
                  <p>
                  Our clients' satisfaction is our top priority. We listen, understand, and tailor our services to meet their unique needs, building strong, lasting relationships. To provide products and services of the highest quality, and the greatest possible value to our customers. We strive to exceed our customers’ expectations, to surprise and delight them – gaining and holding their respect and loyalty.
                  </p>
                </div>

                <div  className="core-values-card primary">
                  <h4><img src={tick}alt="" />Teamwork</h4>
                  <p>
                  Collaboration is key to our success. We value and respect the contributions of every team member, fostering a culture of teamwork, respect, and mutual support. We recognize that it is only through effective teamwork between all our employees that we can achieve our goals. Our commitment is to work as a worldwide team, directed centrally and managed locally, to fulfill the expectations of our staff, customers and others who depend on us.
                  </p>
                </div>
              </div>
          </div>
          <div className="core-values">
            {coreValues.map((item)=>
              <div  className="core-values-card">
              <h4> <img src={tick}alt="" />{item.title}</h4>
              <p>
              {item.description}
              </p>
            </div>

            )}
           
          </div>

        </div>
      </div>
    </AnimatedPage>
  );
};

export default About;
