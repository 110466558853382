import React from 'react'
import "../Stylesheet/quote.css"
import Navbar from '../components/Navbar'



const Quote = () => {

// const [form,setForm] = useState({
//   CustName: '',
//   number: 0,

// })
 

  return (
    <div className="main_quote">
        <Navbar/>
        <div className="quote">
            <div className="qoute_form">
                <form id='form' action="submit"  >
                <h2 className='h2'>Kindly fill this form to request  Service</h2>
                <input type="text" name='Name' placeholder='Name'  />
                <input type="text" name='Number' placeholder='Phone Number' inputMode="numeric"
          pattern="[0-9]*" />
                <input type="Email" name='Email' placeholder='Email' />
                <input type="text" name='Location' placeholder='Your Location' />
                <select name="service" aria-label="Your Request" >
                <option style={{color: "whitesmoke"}} value="" disabled selected>
            Your Request
          </option>
                    <option value="roof">Roof Installation</option>
                    <option value="Fabrication">Fabrication and Erecting</option>
                    <option value="maintainace">Maintenance Service</option>
                    <option value="project">Project Management</option>
                    <option value="supply">Roofing Sheets Supply</option><option value="repair">Roof Repairs</option>
                    <option value="material">Construction Materials</option>
                    <option value="labour">Labour</option>
                    <option value="contractor">General Contractor</option>
                    <option value="capentery">Carpentry</option>
                </select>
                <textarea name="Message" id="" cols="30" rows="10"></textarea>
                <button>Send</button>
            </form>
            </div>
            
        </div>
    </div>
  )
}

export default Quote