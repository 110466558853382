import React, {useState} from 'react'
import AnimatedPage from '../components/AnimatedPage'
import { Header } from '../components/Header'
import serv from "../Image/Frame 467138.png"
import roof from "../Image/rectangle (3).jpg"
import fabri from "../Image/fabri.png"
import main from "../Image/maintain.png"
import project from "../Image/project.png"
import supp from "../Image/rectangle (9).jpg"
import cons from "../Image/construction.png"
import cap from "../Image/capentry.png"
import labour from "../Image/labor.png"
import gen from "../Image/general.png"
import repair from "../Image/rectangle (5).jpg"
import "../Stylesheet/about.css"

const Services = () => {

  const [expandedServices, setExpandedServices] = useState([]);


  const imageArray = [
    {
      image: roof,
      head: "Roof Installation",
      body: "When it comes to roof installation, Vertex Flex Ltd sets the standard for excellence in the construction industry. Our dedicated team of experts combines craftsmanship with the latest industry knowledge to ensure that your roof not only safeguards your property but also enhances its visual appeal.",
      additionalText: "At Vertex Flex Ltd, we understand that The roof of every building is as important as its foundation. We also understand that roof installation projects can be complex and overwhelming. That's why we offer a wide range of services to help you throughout every step of the process. From starting planning to the finishing details.      Efficiency is a hallmark of our service, with projects completed on time, minimizing disruptions. We don't just focus on immediate aesthetics but also long-term value, ensuring your roof is energy-efficient, potentially reducing utility costs. Additionally, our roof installations often include warranties, guaranteeing your investment's security. Environmental responsibility is vital, and we offer eco-friendly roofing options and responsible disposal of old materials.Our commitment to excellence extends to providing warranties and embracing environmental responsibility. Our roofing experts possess a wealth of experience and skills to handle various roofing materials and styles, including shingles, tiles, metal, and flat roofing. We prioritize safety, strictly adhering to safety protocols to protect our team members, your property, and anyone nearby during the installation process.",

    },
    {
      image: fabri,
      head: "Fabrication and Erecting",
      body: "Vertex Flex Ltd stands at the forefront of delivering exceptional quality and precision. Our team of skilled professionals is equipped with the expertise and state-of-the-art equipment to bring your construction vision to life. We offer customized solutions for a wide range of materials and structures, adapting our services to suit your specific requirements.",
      additionalText: "Quality assurance is paramount to us, with strict control measures in place to ensure durability and safety. Our dedication to excellence in fabrication and erecting extends to a wide range of projects, from structural steel fabrication to custom metalwork and concrete construction.  At Vertex Flex Ltd, safety is non-negotiable. We follow rigorous safety protocols to protect our team members and everyone on the construction site, providing you with peace of mind throughout the process. Our commitment to innovation means we leverage the latest construction techniques and technology, enhancing precision and efficiency. Moreover, our comprehensive project management solutions streamline the entire process from concept to completion. This ensures seamless coordination and timely delivery, allowing you to focus on your vision while we handle the details. We also take our environmental responsibility seriously, implementing sustainable practices to minimize waste and reduce our environmental impact. Vertex Flex Ltd is your trusted partner for fabrication and erecting services, where your vision meets our expertise.",
    },
    {
      image: main,
      head: "Maintenance Service",
      body: "At Vertex Flex Ltd, we understand the importance of preserving the longevity and optimal performance of your construction projects. Our team of skilled technicians offers comprehensive maintenance services covering a wide range of building components, from roofing systems to HVAC, plumbing, electrical systems, and structural elements. ",
      additionalText: "We create customized maintenance plans tailored to your property's unique needs, emphasizing preventive measures to identify and address issues before they escalate, ultimately saving you time and money. We also integrate sustainable practices, promoting energy efficiency and responsible waste management in our maintenance services. Whether residential or commercial, Vertex Flex Ltd is your trusted partner for preserving the integrity and functionality of your structures. Our preventive approach involves regular inspections and maintenance to identify potential issues before they become costly problems, extending the lifespan of your property and reducing repair expenses over time. In cases of unexpected issues or emergencies, Vertex Flex Ltd provides swift and reliable emergency maintenance services to minimize disruptions and mitigate damage promptly. ",
    },
    {
      image: project,
      head: "Project Management",
      body: "With Vertex Flex Ltd, you can trust our experienced project managers to oversee every facet of your construction project, from inception to completion. We provide meticulous planning, budget management, resource allocation, and timeline supervision to ensure that your project not only meets but exceeds your expectations.",
      additionalText: "Our goal is to streamline the construction process, minimize delays, and deliver exceptional results within your specified budget. With a focus on clear communication, quality assurance, and efficient coordination, Vertex Flex Ltd's project management ensures that your vision becomes a reality, on time and within budget.Our project management services encompass a wide range of construction projects, whether residential, commercial, or industrial. We understand that effective project management is the cornerstone of a successful endeavor. Our experienced project managers are skilled in balancing various aspects of a project, from procurement and scheduling to risk management and quality control.At Vertex Flex Ltd, we prioritize clear communication throughout the project's lifecycle. We keep you informed every step of the way, ensuring that your vision is understood and executed to your exact specifications",
    },
    {
      image: supp,
      head: "Supplier of Roofing Sheets",
      body: "Vertex Flex Ltd is your trusted supplier of roofing sheets, offering a wide range of high-quality roofing materials to meet your construction needs. Whether you're embarking on a new roofing project or need replacements for an existing structure, we provide a diverse selection of roofing sheets, from traditional options like shingles and tiles to modern and eco-friendly materials.",
      additionalText: "Our roofing sheet supply services are designed to cater to a wide range of construction projects, whether residential, commercial, or industrial. We understand that roofing materials play a pivotal role in protecting your property from the elements while enhancing its overall appearance. Vertex Flex Ltd takes pride in offering roofing sheets that are not only durable but also aesthetically pleasing. We source materials from reputable suppliers to ensure that our roofing sheets meet the highest industry standards. Whether you're looking for traditional roofing options that exude classic charm or eco-friendly materials that promote sustainability, our diverse selection has you covered. What sets us apart as your roofing sheet supplier is our commitment to providing personalized assistance. Our knowledgeable team is ready to guide you in selecting the right roofing materials that align with your project's unique requirements, budget, and design preferences. With Vertex Flex Ltd as your roofing sheet supplier, you can trust that you're receiving reliable products that will withstand the test of time.",
    },
    {
      image: cons,
      head: "Construction Materials",
      body: "Vertex Flex Ltd is your comprehensive source for top-quality construction materials, delivering a wide array of products to meet the demands of your construction projects. Whether you're engaged in residential, commercial, or industrial construction, we provide a diverse selection of construction materials that are built to last. From concrete and steel to insulation and finishing materials, we offer everything you need to bring your construction vision to life.",
      additionalText: "Our commitment to quality ensures that you receive materials that meet or exceed industry standards, contributing to the durability and longevity of your structures. Our construction materials division is well-equipped to serve a wide range of construction projects, each with its unique requirements. We understand that the choice of materials is fundamental to the success and durability of your structures. Vertex Flex Ltd takes great pride in sourcing construction materials from reputable suppliers, guaranteeing the highest levels of quality and reliability.Whether you're in need of foundational elements like concrete and steel or finishing touches like insulation and architectural finishes, our extensive inventory has you covered. What sets us apart is our unwavering commitment to ensuring that you receive materials that not only meet but exceed industry standards. We understand the importance of durability and longevity in construction, and our materials reflect this commitment to quality.",
    },
    {
      image: cap,
      head: "Carpentry",
      body: "Vertex Flex Ltd offers expert carpentry services to enhance your construction projects with precision and craftsmanship. Our skilled carpenters bring years of experience to the table, ensuring that every aspect of your woodworking needs is met. Whether it's framing, cabinetry, custom millwork, or intricate finishing touches, we have the expertise to transform your ideas into beautifully crafted woodwork.",
      additionalText: "Our commitment to quality means that we use the finest materials and techniques, resulting in carpentry work that not only meets but often exceeds your expectations. With Vertex Flex Ltd as your carpentry partner, you can trust that your construction project will showcase impeccable woodwork that stands the test of time. Our carpentry services extend across a wide range of construction projects, from residential renovations to commercial and industrial structures. We understand that carpentry is a vital component of enhancing both the functionality and aesthetics of your properties. Vertex Flex Ltd takes great pride in the precision and artistry of our carpentry work. We employ highly skilled carpenters who are well-versed in various aspects of woodworking, allowing us to offer a versatile range of services to meet your unique needs. What sets us apart is our unwavering commitment to quality. We use the finest materials, employ time-tested techniques, and pay meticulous attention to detail to ensure that every piece of carpentry work we deliver is of the highest caliber",
    },
    {
      image: labour,
      head: "Labor",
      body: "Vertex Flex Ltd provides skilled and reliable labor services to support all aspects of your construction projects. Our labor force consists of highly trained professionals who are dedicated to ensuring the successful execution of your project. Whether you need construction laborers, craftsmen, or specialized workers, we have a diverse and capable team ready to meet your needs.",
      additionalText: "Our commitment to quality extends to our labor services, and we prioritize safety, efficiency, and expertise in every task we undertake. We understand that labor is the backbone of any construction project, and we take pride in offering a workforce that is not only skilled but also adaptable to a variety of project types and sizes. With Vertex Flex Ltd as your labor provider, you can trust that your projects will be staffed with experienced and dependable workers who are committed to meeting deadlines and maintaining the highest standards of workmanship. Our labor services cater to a wide range of construction projects, from residential builds to large-scale commercial and industrial endeavors. We understand that the quality of labor directly impacts the outcome of your project. Vertex Flex Ltd stands out for its commitment to providing skilled and dependable laborers who prioritize safety and efficiency. We maintain strict safety protocols to ensure a secure work environment, safeguarding both our team members and the success of your project.",
    },
    {
      image: gen,
      head: "General Contractor",
      body: "As a general contractor, Vertex Flex Ltd takes on the responsibility of overseeing and managing every aspect of your construction project. With a wealth of experience and expertise, we serve as the central point of contact, ensuring that all elements of your project, from planning and design to execution and completion, run smoothly and efficiently.",
      additionalText: "We prioritize quality, safety, and timely completion, ensuring that your project aligns with your vision and budget. By choosing Vertex Flex Ltd as your general contractor, you gain a partner dedicated to bringing your construction dreams to life, with a commitment to excellence at every step of the journey. Our role as a general contractor extends to a wide range of construction projects, whether residential, commercial, or industrial. We understand the importance of effective project management in delivering successful outcomes. At Vertex Flex Ltd, our experienced team collaborates closely with you to understand your specific needs and goals. We oversee every aspect of your project, from procurement and scheduling to quality control and budget management. Our aim is to ensure that your project is not only completed on time but also exceeds your expectations. Our commitment to quality means that we follow industry best practices, use high-quality materials, and employ skilled professionals in every task we undertake. We also maintain rigorous safety protocols to safeguard our team members and the integrity of your project.",
    },
    {
      image: repair,
      head: "Roof Repairs",
      body: "When it comes to roof repairs, we understand that it's natural to fear the worst when you notice issues like a leaking roof. Many people immediately assume that they will need to bear the burden and expense of a whole new roof system. However, at Vertex Flex Ltd, we want to reassure you that often, the situation is not as dire as it may seem.",
      additionalText: "Most roof leaks are isolated to specific areas rather than affecting the entire roof structure. Our team of highly skilled professionals excels in pinpointing the source of leaks and addressing them with precision. When you turn to Vertex Flex Ltd for roof repairs, you can trust us to put an end to that troublesome leaking roof, saving you both time and money. We believe in providing cost-effective solutions that extend the life of your existing roof. Our goal is to help you avoid the unnecessary expense of a full roof replacement whenever possible. By leveraging our expertise and state-of-the-art techniques, we ensure that your roof repairs are carried out efficiently and effectively. Additionally, we offer preventative maintenance services to help identify potential issues before they become major problems. Regular roof maintenance can extend the lifespan of your roof and reduce the need for extensive repairs in the future. At Vertex Flex Ltd, we are dedicated to being your trusted roof repair partner. ",
    },
    
  ];
  // const toggleService = (index) => {
  //   if (expandedServices.includes(index)) {
  //     setExpandedServices((prevExpandedServices) =>
  //       prevExpandedServices.filter((i) => i !== index)
  //     );
  //   } else {
  //     setExpandedServices((prevExpandedServices) =>
  //       [...prevExpandedServices, index]
  //     );
  //   }
  // };
  const toggleService = (index) => {
    if (expandedServices.includes(index)) {
      setExpandedServices(expandedServices.filter(item => item !== index));
    } else {
      setExpandedServices([...expandedServices, index]);
    }
  };
  
  

  return (
    <AnimatedPage>
      <div className="main_services">
      <Header image ={serv} title="Services" />
      <div className="services">
        {imageArray.map((item, index)=>
          <div  key={index} className={`array ${index % 2 === 0 ? 'white-bg' : 'grey-bg'}`}>
            <div data-aos="fade-right" data-aos-duration="1000" className="array_text">
              <h2>{item.head}</h2>
              <p> {item.body}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {expandedServices.includes(index) && item.additionalText && (
                    <>
                      <br />
                      {item.additionalText} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  {item.additionalText && (
                    <span onClick={() => toggleService(index)}>
                      {expandedServices.includes(index) ? 'Read less' : 'Read more'}
                    </span>
                  )}</p>
            </div>
            <img data-aos="fade-left" data-aos-duration="1000" src={item.image} alt="services_images" />
          </div>
        )}
      </div>
      </div>
      </AnimatedPage>
  )
}

export default Services