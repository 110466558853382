import React from "react";
import AnimatedPage from "../components/AnimatedPage";
import { Header } from "../components/Header";
import cont from "../Image/Frame 467139.png";
import { FaFacebookF } from "react-icons/fa";
import "../Stylesheet/styles.css"
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <AnimatedPage>
      <div className="main_contact">
        <Header
          image={cont}
          title="Contact "
          body="Reach out to us for all your roofing and construction services"
        />
        <div className="contact">
          <div data-aos="fade-up-right" data-aos-duration="1500" data-aos-easing="ease-out-cubic"className="contact_text">
            <div className="conti">
              <h2>Head Office</h2>
              <p>No 2. Olabisi Close, Odogunyan Ikorodu, Lagos State.</p>
            </div>
            <div className="conti">
              <h2>Hotline</h2>
              <p>+234 701- 042-2306</p>
            </div>
            <div className="social">
              <p>Follow Our Social Media</p>
              <div className="contact_icon">
                {/* <a href="/">
                  <AiFillInstagram />
                </a>
                <a href="/">
                  <AiOutlineTwitter />
                </a>
                <a href="/">
                  <FaLinkedin/>
                </a> */}
                <Link to="https://www.facebook.com/profile.php?id=100063491323555&mibextid=ZbWKwL" target="_blank">
                  <FaFacebookF />
                </Link>
              </div>
            </div>
          </div>

        <form data-aos="fade-up-left" data-aos-duration="1500" data-aos-easing="ease-out-cubic" action="submit">
          <div className="name">
            <div className="step">
              <label htmlFor="firstName">First Name</label>
              <input type="text" name="firstName" id="" placeholder="First name"/>
            </div>
            <div className="step">
              <label htmlFor="lasttName">Last Name</label>
              <input type="text" name="lastName" id="" placeholder="Last name"/>
            </div>
          </div>
          <div className="step">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="" placeholder="Email address"/>
            </div>
          <div className="step">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="" cols="30" rows="10" placeholder='Your message here'></textarea>
          </div>
          <button>Get in touch</button>
        </form>

        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.11297545373!2d3.5181865744363807!3d6.63288862188363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103beec0810ad52b%3A0x4f0d71c6aafac7e0!2s2%20Olabisi%20Cl%2C%20Ikorodu%20104101%2C%20Ikorodu%2C%20Lagos!5e0!3m2!1sen!2sng!4v1695945224871!5m2!1sen!2sng" width="100%" height="100vh" style={{ border: "0", height: "60vh" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
      </div>
    </AnimatedPage>
  );
};

export default Contact;
